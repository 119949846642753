<template>
    <!-- 本地关注人员分类 -->
    <div class="classification-admin">
        <div class="accountSearch">
            <div>
                <el-button @click="addDoorClick" class="titleButton" type="primary" icon="el-icon-download">添加分类</el-button>
            </div>
        </div>
        <div class="accountTableC">
            <div class="companyTable">
                <el-table v-loading="loading" :data="deviceData.elements" style="width: 100%" height="100%">
                    <el-table-column prop="name" label="分类名称" min-width="120">
                    </el-table-column>
                    <el-table-column prop="tipsText" label="拒绝通行提示文字" align="center" min-width="200">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="添加时间" align="center" min-width="200">
                    </el-table-column>
                    <el-table-column label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button @click="editClick(scope.row, scope.$index)" type="text">编辑</el-button>
                            <el-button @click="deleteClick(scope.row, scope.$index)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination :current-page="currentPage" :page-size="deviceData.pageSize" @current-change="handleCurrentChange" background layout="total, prev, pager, next" :total="deviceData.totalRecords">
            </el-pagination>
        </div>
        <!-- 弹窗 添加/编辑分类 -->
        <addEditClassification v-if="addEditAccountShow" @cancelAgent-fun="cancelAgentFun" @deterAgent-fun="deterAgentFun" :catdTitle="catdTitle" :agentList="deviceData.elements[agentId]"></addEditClassification>
        <!-- 弹窗 删除 -->
        <deletePop v-if="deletePopShow" @deletePop-fun="deletePopFun" @determineDel-fun="determineDelFun" :catdTitle="catdTitle" :companyName="deviceData.elements[agentId].name" :name="deleteName"></deletePop>
    </div>
</template>
<script>
    import addEditClassification from '../../components/addEditClassification'
    import deletePop from '../../components/deletePop'

    export default {
        name: 'classification-admin',
        components: { addEditClassification, deletePop },
        data() {
            return {
                loading: false,
                // 是否显示 弹窗 添加/编辑分类
                addEditAccountShow: false,
                // 是否显示 弹窗 删除
                deletePopShow: false,
                // 搜索框 值
                input: '',
                // 拉取到的 表格数据
                deviceData: {},
                // 当前页数
                currentPage: 1,
                //列表-开始位置
                start: 1,
                // 弹窗 标题
                catdTitle: '',
                // 被选中下标
                agentId: null,
                deleteName: '的分类信息',
            }
        },
        created() {
            this.equipmentList()
        },
        methods: {
            // 弹窗 添加/编辑分类 取消
            cancelAgentFun() {
                this.addEditAccountShow = false
            },
            // 弹窗 添加/编辑分类 提交
            deterAgentFun() {
                this.addEditAccountShow = false
                this.equipmentList()
            },
            // 点击 添加分类
            addDoorClick() {
                this.catdTitle = '添加分类'
                this.addEditAccountShow = true
            },
            // 点击 编辑分类
            editClick(val, index) {
                this.agentId = index
                this.catdTitle = '编辑分类'
                this.addEditAccountShow = true
            },
            // 代理商管理 列表
            equipmentList() {
                this.loading = true
                this.$instance
                    .get('/v1/follow_class/list', {
                        params: {
                            pageNum: this.currentPage - this.start,
                            pageSize: 10,
                        },
                    })
                    .then(res => {
                        if (res.data.status === 200) {
                            this.loading = false
                            this.deviceData = res.data.data
                            this.deviceData.elements.map(val => {
                                if (val.role === 1) {
                                    val.roleC = '超管'
                                } else if (val.role === 3) {
                                    val.roleC = '区域代理商'
                                } else if (val.role === 4) {
                                    val.roleC = '代理商'
                                }
                            })
                        }
                    })
                    .catch(error => {
                        console.log('error: ', error)
                    })
            },
            // 点击搜索
            searchClick() {
                this.currentPage = 1
                this.equipmentList()
            },
            // 点击分页-当前页
            handleCurrentChange(val) {
                this.currentPage = val
                // 搜索并拉取列表
                this.equipmentList()
            },
            // 点击 删除
            deleteClick(val, index) {
                this.agentId = index
                this.catdTitle = '删除分类'
                this.deletePopShow = true
            },
            // 弹窗 删除 取消
            deletePopFun() {
                this.deletePopShow = false
            },
            // 弹窗 删除 确定
            determineDelFun() {
                this.$instance
                    .post(
                        '/v1/follow_class/del?id=' +
                            this.deviceData.elements[this.agentId].id
                    )
                    .then(res => {
                        if (res.data.status === 200) {
                            this.deletePopShow = false
                            // 搜索并拉取列表
                            this.equipmentList()
                            this.$message({
                                message: this.catdTitle + '成功！',
                                type: 'success',
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
        },
    }
</script>
<style lang="scss" scoped>
    .classification-admin {
        width: calc(100% - 40px);
        margin: 20px;
        background-color: #fff;
        // 搜索
        & ::v-deep .accountSearch {
            width: 100%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            box-sizing: border-box;
            border-bottom: 1px #f0f0f0 solid;
            .el-button--primary {
                line-height: 32px;
                padding: 0 12px;
                border: unset;
            }
            .titleSearch {
                display: flex;
                .el-select > .el-input,
                .el-input {
                    margin-right: 6px;
                }
                .el-select > .el-input {
                    width: 150px;
                }
                .el-input {
                    width: 240px;
                    .el-input__inner {
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        i {
                            line-height: 32px;
                        }
                    }
                }
            }
        }
        .accountTableC {
            width: calc(100% - 40px);
            height: calc(100% - 144px);
            margin: 20px;
            // 表格
            & ::v-deep .companyTable {
                height: 100%;
                border: 1px #f0f0f0 solid;
                overflow: auto;
                .el-table td,
                .el-table th {
                    padding: 4px 0;
                }
            }
        }
    }
</style>
